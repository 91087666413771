import React from 'react';
import './App.css';
import './PageProductos.css';
import Header from './Header';
import Footer from './Footer';


function PageProductos() {

  return (
    <div className="PageProductos">
      <Header />
      <main className="App-body">
        <div className="PageProductosBody">
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default PageProductos;
