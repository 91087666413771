import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [showModal, setShowModal] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Nuevo estado para el menú

  const handleButtonClick = (url) => {
    if (privacyAccepted !== url) {
      setRedirectUrl(url);
      setShowModal(true);
    } else {
      window.open(url, '_blank', 'noopener noreferrer');
    }
  };

  const handleAccept = () => {
    setPrivacyAccepted(redirectUrl);
    setShowModal(false);
    window.open(redirectUrl, '_blank', 'noopener noreferrer');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Alterna el estado del menú
  };

  return (
    <header className="App-header">
      <div className="header-content">
        <div className="logo">
          <span>
            <a href="https://gusticoscakes.com">
              <img
                src='./img/logo_new.png'
                className="custom-logo"
                alt="gusticoscakes.com"
              />
            </a>
          </span>
        </div>

        <div className={`menu_principal ${isMenuOpen ? 'open' : ''}`}>
          <span>
            <a href="/principal" className="menu-link">
              Principal
            </a>
          </span>
          <span className="menu-separator"></span>
          <span>
            <a href="/nosotros" className="menu-link">
              Nosotros
            </a>
          </span>
          <span className="menu-separator"></span>
          <span>
            <a href="/contactanos" className="menu-link">
              Contáctanos
            </a>
          </span>
          <span className="menu-separator"></span>
          <span>
            <a href="/pagos" className="menu-link">
              Pagos
            </a>
          </span>
        </div>

        <div className="header-buttons">
          <button
            className="btn-instagram"
            onClick={() => handleButtonClick('https://www.instagram.com/gusticoscakes/')}
          >
            Instagram
          </button>
          <button
            className="btn-whatsapp"
            onClick={() => handleButtonClick('https://wa.me/+34603294260')}
          >
            WhatsApp
          </button>
        </div>

        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776; {/* Icono de hamburguesa */}
        </button>

      </div>

      {showModal && (
        <div className="modal-politicas">
          <div className="modal-politicas-content">
            <p>Al hacer clic en el botón de "Aceptar y continuar", usted confirma que ha leído y acepta nuestras políticas de privacidad.</p>
            <p>Para más información consultalas en:</p>
            <ul>
              <li><a href="./../aviso-legal">Aviso Legal</a></li>
              <li><a href="./../politica-privacidad">Política de Privacidad</a></li>
              <li><a href="./../politica-cookies">Política de Cookies</a></li>
            </ul>
            <div className="modal-politicas-buttons">
              <button onClick={handleAccept}>Aceptar y continuar</button>
              <button onClick={() => setShowModal(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
