import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import CookieBanner from "./CookieBanner";

function Footer() {
    return (
        <footer className="Footer">
            <div className="Footer_main">
                <div className="Footer_center">
                    <p>
                        © 2024 gusticoscakes.com Todos los derechos reservados
                        <span className="pink-separator"> | </span>
                        <Link to="/aviso-legal" className="legal-link">Aviso Legal</Link>
                        <span className="pink-separator"> | </span>
                        <Link to="/politica-cookies" className="legal-link">Politica de cookies</Link>
                        <span className="pink-separator"> | </span>
                        <Link to="/politica-privacidad" className="legal-link">Politica de privacidad</Link>
                        <span className="pink-separator"> | </span>
                        Powered by gusticoscakes.com
                    </p>
                </div>
            </div>
            <CookieBanner />
        </footer>
    );
}

export default Footer;
