import React, { useState, useEffect } from 'react';
import './Productos.css';

function Productos() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Asegúrate de que esta ruta sea correcta
    fetch('/productos.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setProducts(data))
      .catch(error => console.error('Error al cargar los productos:', error));
  }, []);

  return (
    <section className="Productos">
      <h1>PRODUCTOS</h1>
      <div className="product-list">
        {products.length === 0 ? (
          <p>Cargando productos...</p>
        ) : (
          products.map(product => (
            <div className="product-card" key={product.id}>
              <div className="product-image-container">
                <img src={product.image} alt={product.name} className="product-image" />
              </div>
              <div className="product-details">
                <h2 className="product-name">{product.name}</h2>
                <p className="product-description">
                  {product.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
}

export default Productos;
