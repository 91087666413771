import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    // Verifica si el usuario ya aceptó o rechazó las cookies
    const cookiesDecision = localStorage.getItem("cookiesAccepted");
    if (!cookiesDecision) {
      setIsBannerVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    // Guarda en localStorage que el usuario aceptó las cookies
    localStorage.setItem("cookiesAccepted", "true");
    setIsBannerVisible(false);
  };

  const rejectCookies = () => {
    // Guarda en localStorage que el usuario rechazó las cookies
    localStorage.setItem("cookiesAccepted", "false");
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div style={styles.banner}>
      <p>
        Usamos cookies propias y de terceros para mejorar tu experiencia y fines analíticos.
        Puedes aceptar todas las cookies pulsando "Aceptar" o "Rechazar" el uso de cookies.
      </p>
      <p>
        Para más información, visita nuestra{" "}
        <a href="./../politica-cookies" style={styles.link}>Política de Cookies</a>.
      </p>

      <button onClick={acceptCookies} style={styles.buttonAccept}>
        Aceptar
      </button>
      <button onClick={rejectCookies} style={styles.buttonReject}>
        Rechazar
      </button>
    </div>
  );
};

const styles = {
  banner: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#333",
    color: "#fff",
    padding: "20px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  link: {
    color: "#ff8400",
    textDecoration: "underline", 
  },
  buttonAccept: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    marginRight: "10px",
  },
  buttonReject: {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
  },
};

export default CookieBanner;
