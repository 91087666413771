import React from 'react';
import './App.css';
import './Nosotros.css';
import Header from './Header';
import Footer from './Footer';

function Nosotros() {
  return (
    <div className="Nosotros">
      <Header />
      <main className="App-body">

        <div className="NosotrosBody">

          <div className="NosotrosMensaje">

            <h2 className='NosotrosMensajeTitulo'>GusticosCakes</h2>
            <p>
              Nuestro sueño. Un emprendimiento pequeño que nacio en el año 2018  de manera tímida,
              pero con muy grandes expectativas de lograr un gran proyecto.
              Con la firme convicción de brindar a nuestros clientes dulces momentos repletos de alegría
              y sabor con la mejor calidad, entregando en cada uno de nuestros productos la esencia
              de lo que somos.
            </p>

            <br></br>

          </div>

        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Nosotros;