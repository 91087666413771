import React from 'react';
import './Contactenos.css';
import Separador from './Separador';

function Contactenos() {
    return (
        <section className="Contactenos">
            <div className="overlay_s2"></div>
            <div className="contactenos_main">
                <div className="titulo">
                    <h2>Contáctanos</h2>
                </div>
                <div className="mensaje">
                    <p>Llámanos para brindarte una asesoría personalizada.</p>
                </div>
                <div className="contactenos_info">
                    Tel: (+34) 603 294 260
                    <br></br>
                    store.gusticoscakes@gmail.com
                    <br></br>
                    Madrid, España
                    <br></br>
                </div>
                <br></br>
                <div className="icono_social">
                    <span>
                        <a className="icono_facebook" href="https://www.facebook.com/Gusticoscakes-807462666112997"
                            target="_blank" rel="noopener noreferrer">
                            <img src='./img/logo_facebook.png' alt="Facebook" />
                        </a>
                    </span>
                    <span>
                        <a className="icono_instagram" href="https://www.instagram.com/gusticoscakes/"
                            target="_blank" rel="noopener noreferrer">
                            <img src='./img/logo_instagram.png' alt="Instagram" />
                        </a>
                    </span>
                </div>
            </div>
        </section>
    );
}

export default Contactenos;
