import React from 'react';
import './App.css';
import './Pagos.css';
import Header from './Header';
import Footer from './Footer';

function Pagos() {
  return (
    <div className="Pagos">
      <Header />
      <main className="App-body">

        <div className="PagosBody">

          <div className="PagosContent">

            <h2 className='PagosMensajeTitulo'>Medios de Pago</h2>

            <div className="PagosMensaje">

              <p>Aceptamos los siguientes métodos de pago para su comodidad.</p>

              <br></br>

              <ul>
                <li><strong>Efectivo</strong></li>
                <li><strong>Bizum:</strong> Puede realizar su pago fácilmente a través de Bizum al número 603 294 260.</li>
                <li><strong>Transferencia Bancaria:</strong> También aceptamos transferencias a nuestra cuenta en BBVA, con el siguiente número IBAN: ES95 0182 5322 2002 0807 2843.</li>
                <li><strong>Pago con Tarjeta:</strong> Si lo prefiere, puede pagar con tarjeta de crédito o débito.</li>
              </ul>

              <br></br>

            </div>

          </div>

        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Pagos;