import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CarruselImg.css';

const images = [
    './img/IMG_CS_01.png',
    './img/IMG_CS_02.png',
    './img/IMG_CS_03.png',
    './img/IMG_CS_04.png',
    './img/IMG_CS_05.png',
    './img/IMG_CS_06.png',
    './img/IMG_CS_07.png'
];

function CarruselImg() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="CarruselImg">
        <div className="CarruselImg_content">
            <Slider {...settings}>
                {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} />
                </div>
                ))}
            </Slider>
        </div>
    </section>
  );
}

export default CarruselImg;
