import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import PoliticaAvisoLegal from './PoliticaAvisoLegal';
import PoliticaPrivacidad from './PoliticaPrivacidad';
import PoliticaCookies from './PoliticaCookies';
import Nosotros from './Nosotros';
import Contactanos from './Contactanos';
import Pagos from './Pagos';
import PageProductos from './PageProductos';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/principal" element={<HomePage />} />
        <Route path="/aviso-legal" element={<PoliticaAvisoLegal />} />
        <Route path="/politica-cookies" element={<PoliticaCookies />} />
        <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/contactanos" element={<Contactanos />} />
        <Route path="/pagos" element={<Pagos />} />
        <Route path="/productos" element={<PageProductos />} />
      </Routes>
    </Router>
  );
};

export default App;