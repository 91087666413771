import React from 'react';
import './PoliticaCookies.css';
import Header from './Header';
import Footer from './Footer';

function PoliticaCookies() {
  return (
    <div className="PoliticaCookies">
      <Header />
      <main className="App-body">

        <div className="PoliticaCookiesBody">

          <h1>POLÍTICA DE COOKIES</h1>
          <p>
          En cumplimiento con el artículo 22 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, General de Protección de Datos (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos y garantía de los derechos digitales (LOPDGDD), 
          el Titular le informa a los usuarios la Política de recogida y tratamiento de cookies del sitio Web.
          </p>

          <h2>¿Qué son las cookies?</h2>
          <p>
            Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
          </p>

          <h2>¿Qué tipos de cookies utiliza esta página web?</h2>
          <p>
            <strong>Cookies de análisis</strong> - Son aquellas que, bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
          </p>
          <p>
            <strong>Cookies publicitarias</strong> - Son aquellas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.
          </p>

          <h2>Finalidad de las cookies</h2>
          <p>Utilizamos cookies para:</p>
          <ul>
            <li>Asegurar el correcto funcionamiento del sitio web.</li>
            <li>Personalizar la experiencia del usuario, recordando sus preferencias.</li>
            <li>Analizar el uso del sitio para mejorar su contenido y estructura.</li>
            <li>Mostrar publicidad adaptada a sus intereses.</li>
          </ul>

          <h2>Cómo desactivar las Cookies</h2>
          <p>
            Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.
          </p>
          <p>
            A continuación, puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:
          </p>
          <ul>
            <li><a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank">Firefox</a></li>
            <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/es-es/microsoft-edge" target="_blank">Microsoft Edge</a></li>
            <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
          </ul>

          <h2>Cookies propias</h2>
          <p>La página utiliza una cookie propia para definir la utilización de estas si fuese necesario "cookiesAccepted" .</p>

          <h2>Cookies de Terceros</h2>
          <p>
            Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.
          </p>
          <p>
            La información que se obtiene se refiere, por ejemplo, al número de páginas visitadas, el idioma, el lugar desde el que accede el Usuario, el número de Usuarios que acceden, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de dispositivo desde el que se realiza la visita. Esta información se utiliza para mejorar el Sitio Web, y detectar nuevas necesidades para ofrecer a los Usuarios un Contenido y/o servicio de óptima calidad.
          </p>

          <p>
            Puede obtener más información sobre las cookies, la información sobre la privacidad, o consultar la descripción del tipo de cookies que se utiliza, sus principales características, periodo de expiración, etc. en los siguientes enlaces:
          </p>
          <ul>
            <li><a href="https://policies.google.com/technologies/cookies?hl=es" target="_blank">https://policies.google.com/technologies/cookies?hl=es</a></li>
            <li><a href="https://developers.google.com/analytics" target="_blank">https://developers.google.com/analytics</a></li>
          </ul>

          <h2>Cookies orientadas a Google Analytics</h2>
          <ul>
            <li><strong>_ga:</strong> Distinguir a los usuarios únicos asignándoles un identificador único. (Duración: 2 años)</li>
            <li><strong>_gid:</strong> Almacena y actualiza un identificador único para cada página visitada. (Duración: 24 horas)</li>
            <li><strong>_gat:</strong> Limitar la cantidad de solicitudes que se envían a los servidores de Google Analytics. (Duración: 1 minuto)</li>
            <li><strong>gac*:</strong> Almacenar información relacionada con campañas publicitarias. (Duración: 90 días)</li>
            <li><strong>_utma:</strong> Rastrea la primera visita, la última visita y el número total de visitas. (Duración: 2 años)</li>
            <li><strong>_utmb:</strong> Determina nuevas sesiones/visitas. (Duración: 30 minutos)</li>
            <li><strong>_utmc:</strong> Trabaja con _utmb para determinar si el usuario está en una nueva sesión/visita. (Duración: Expira al cerrar el navegador)</li>
            <li><strong>_utmz:</strong> Rastrea la fuente del tráfico. (Duración: 6 meses)</li>
            <li><strong>_utmv:</strong> Almacena datos variables personalizados a nivel de usuario. (Duración: 2 años)</li>
          </ul>

          <h2>¿Cómo puede gestionar las cookies?</h2>
          <p>Puede gestionar y eliminar cookies a través de las siguientes opciones:</p>
          <ul>
            <li>Ajustes del navegador: Configure su navegador para aceptar, bloquear o eliminar las cookies.</li>
          </ul>

          <h2>Aceptación y cambios en la política de cookies</h2>
          <p>
            Al utilizar este sitio web, usted acepta el uso de cookies en los términos descritos en esta Política de Cookies. Puede cambiar sus preferencias en cualquier momento.
          </p>
          <p>
            Nos reservamos el derecho de modificar esta política para adaptarla a novedades legislativas o técnicas. Cualquier cambio será publicado en esta misma página.
          </p>

          <h2>Tratamiento de Datos Personales</h2>
          <p>
            El Titular es el Responsable del tratamiento de los datos personales del usuario. Puede consultar toda la información relativa al tratamiento de datos personales que recoge el Titular en la página de la <a href="./../politica-privacidad" target="_blank">Política de Privacidad</a>.
          </p>

          <h2>Consideraciones</h2>
          <h3>Uso de cookies para recordar decisiones de no aceptación</h3>
          <p>
            Para garantizar que su decisión de no aceptar cookies sea respetada, es necesario almacenar una cookie específica en su navegador. Esta cookie nos permite recordar su elección y evitar que se le solicite nuevamente su consentimiento en futuras visitas. Sin esta cookie, no sería posible retener su preferencia de no aceptación.
          </p>

          <h3>Responsabilidad sobre políticas de terceros</h3>
          <p>
            Esta web y sus responsables legales no se hacen responsables del contenido o la veracidad de las políticas de privacidad de los servicios de terceros mencionados en nuestra política de cookies. Recomendamos que consulte directamente las políticas de privacidad de dichos terceros para obtener información más precisa y actualizada sobre cómo gestionan sus datos y el uso de cookies.
          </p>

          <h3>Google Analytics y ubicación de los servidores</h3>
          <p>
            Las cookies de Google Analytics son almacenadas en servidores ubicados en los Estados Unidos. Google se compromete a no compartir esta información con terceros, excepto cuando sea necesario para el correcto funcionamiento de su sistema o por requerimientos legales. Según Google, no se almacenan direcciones IP completas, lo que ayuda a proteger su anonimato.
          </p>
          <p>
            Google Inc. está adherido al marco del "Privacy Shield" (Escudo de Privacidad), lo que garantiza que los datos transferidos serán tratados con un nivel adecuado de protección, en conformidad con las normativas de protección de datos de la Unión Europea. Para más información sobre las prácticas de Google, puede consultar su política de privacidad en los siguientes enlaces:
          </p>
          <ul>
            <li><a href="https://policies.google.com/privacy" target="_blank">Información sobre el uso de cookies por Google</a></li>
          </ul>

          <h2>Contacto</h2>
          <p>
            Si tiene dudas sobre esta Política de Cookies, puede contactarnos a través del correo electrónico: <a href="mailto:store.gusticoscakes@gmail.com">store.gusticoscakes@gmail.com</a>.
          </p>

          <br></br>

        </div>
        <div className='accion_home'>
          <a href="./.."> Pagina principal </a>
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default PoliticaCookies;