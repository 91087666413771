import React from 'react';
import './App.css';
import './Contactanos.css';
import Contactenos from './Contactenos';
import Header from './Header';
import Footer from './Footer';

function Contactanos() {
  return (
    <div className="Contactanos">
      <Header />
      <main className="App-body">

        <div className="ContactanosBody">

          <div className="ContactanosMensaje">

           <Contactenos />

            <br></br>

          </div>

        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Contactanos;